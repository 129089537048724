<template>
  <v-footer color="black" dark min-height="300">
    <v-row
      justify="center"
      no-gutters
    >
      <v-col cols="12" class="text-center">
        <v-btn text rounded to="/">Accueil</v-btn>
        <!-- <v-btn text rounded to="/register">S'inscrire</v-btn>
        <v-btn text rounded to="/login">Se connecter</v-btn> -->
        <!-- <v-btn text rounded to="/gallery" class="d-none d-sm-inline-flex">Galerie</v-btn> -->
        <!-- <v-btn text rounded to="/shop" class="d-none d-sm-inline-flex">Boutique</v-btn> -->
        <v-btn text rounded to="/contact">Contact</v-btn>
        <v-btn text rounded to="/conditions">CGV</v-btn>
        <br>
        <br>
        <span class="font-flower" style="font-size:30px;">librarie.nanoug<small>.com</small></span>
        <br><br>
        <v-btn icon class="mx-3" target="_blank" href="https://www.facebook.com/daniele.goguet">
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
        <!-- <v-btn icon class="mx-3"><v-icon>mdi-instagram</v-icon></v-btn> -->
        <v-btn icon class="mx-3" to="/contact"><v-icon>mdi-at</v-icon></v-btn>
        <br><br>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>

//import router from '../../router/router'

const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'footer',

  data: () => ({
  }),
  
  mounted: function(){
  },
  
  methods: {
  },

  computed:{
    urlCGV: function(){ return config.root_node.url + ':' + config.root_node.port + '/CGV-tomdusauvage.pdf' }
  }
    
};
</script>
