
import axios from "axios"
import { i18n } from 'vue-lang-router'

const config = require('../../config/' + process.env.NODE_ENV)

const state = () => ({
  isLoading: true,
  drawerOpen: false,

  data: { 
    product : [],
    extra: [],
    config: [],
    instance: [],
    comment: [],
    category: []
  },

  langAvailables : [],

  snackbar: {
    show: false,
    title: "",
    text: "",
    color: "",
    icon: "",
    path: null
  },

  showDialogMailDev: false,
  dialogMailDev: null,

  siteName: "Nanoug' librairie",
});

const actions = {
  setDrawerOpen({ commit }, open) {
    commit("setDrawerState", open);
  },
  setAppLoading({ commit }, isLoading) {
    commit('setLoadingState', isLoading);
  },
  async initLang({ commit }) {
    const { data } = await axios.post('/data/get-lang-availables')
    commit('initLang', data.languages);
  },

  async fetchEntities({ commit, state }, params){
    console.log("fetchEntities", params.entityType, state.data[params.entityType].length, params.limit)
    //si on a déjà chargé un type, on ne recommence pas
    //if(state.data[entityType].length > 0) return
    //si on en a aucune, on les charge toutes
    const { data } = await axios.post('/data/query-entities', { entityType: params.entityType, 
                                                                lang: i18n.locale, 
                                                                limit: params.limit, 
                                                                query: params.query, 
                                                                sort: params.sort })

    if (data.error === false) {
      commit("setEntities", { 'entities' : data.entities, 'entityType' : params.entityType })
      return data.entities
    }
  },

  openSnackbar({ commit }, params) {
    commit("openSnackbar", params)
  },
  openDialogMailDev({ commit }, params) {
    //console.log("process.env.NODE_ENV", process.env.NODE_ENV)
    if(process.env.NODE_ENV != "production")
      commit("openDialogMailDev", params)
  },
  closeDialogMailDev({ commit }, params) {
    commit("closeDialogMailDev", params)
  },
  async incStat({ commit }, url){
    console.log("incStat", config.domaineName, url)
    axios.post('/stat/inc-stat-url', { domaineName: config.domaineName, url: url })
    commit('statSent', false)
  },
};

const mutations = {
  setDrawerState(state, open) {
    state.drawerOpen = open;
  },
  setLoadingState(state, isLoading) {
    state.isLoading = isLoading;
  },
  setEntities(state, data){
    state.data[data.entityType] = data.entities
  },
  initLang(state, languages){
    state.langAvailables = languages
  },
  openSnackbar(state, params) {
    state.snackbar = params
    if(params.show == false) state.snackbar.text = ""
    if(params.show == false) state.snackbar.title = ""
    if(params.show == false) state.snackbar.path = null
  },
  openDialogMailDev(state, params) {
    state.dialogMailDev = params
    state.showDialogMailDev = true
  },
  closeDialogMailDev(state) {
    state.showDialogMailDev = false
  },
  statSent(state, bool) {
    state.isLoading = bool
  },
};

const getters = {
  
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
